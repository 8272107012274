import React, { Component } from "react";
import { Text, Button, View } from "react-native";
import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/storage";
import { getDatabase } from "../models/firestore";
import * as alasql from "alasql";
import blackpixel from "../images/black1px.png";
import lichtgroen from "../images/lichtgroen.png";
import lichtblauw from "../images/lichtblauw.png";
import rood from "../images/rood.png";
import white from "../images/white.png";
import oranje from "../images/oranje.png";
import lichtgeel from "../images/lichtgeel.png";
import blauw from "../images/blauw.png";
import lichtgrijs from "../images/lichtgrijs.png";
import html2canvas from "html2canvas";
import { ToastContainer, toast, Zoom } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./Matrix.css";
import _ from 'lodash'

const firestore = firebase.firestore();
//firestore.settings({ timestampsInSnapshots: true });
const __DEV__ = window.location.hostname === "localhost";

class Matrix extends Component {
  state = {
    spelers: [],
    gespeeldewed: [],
    dagdeel: "",
    aantalwed: [],
    wachtrij: [],
    Beurtenlimiet: 0,
    Spelsoort: "LibreKaderBS",
    Update: Date.now(),
    GeenfotoURL: "",
    validUser: false,
    Competitie: false,
    CompetitieRonde: 1
  };

  async componentDidMount() {
    let dagdeel = "";
    let Beurtenlimiet = 0;
    let tafelstegebruiken = [1, 2, 3, 4, 5, 6]
    let Spelsoort = "LibreKaderBS";
    let CompetitieRonde = this.state.CompetitieRonde;
    var keys = this.props.avond; //urlParams.get("avond");

    if (keys === null) {
      keys = "niets";
    }

    console.log(keys);

    switch (keys.toLowerCase()) {
      case "maandagavond":
        dagdeel = "Maan_av";
        Beurtenlimiet = 25;
        break;
      case "dinsdagmiddag":
        dagdeel = "Dins_mid";
        tafelstegebruiken = [1, 2, 3, 4, 5, 6]
        Beurtenlimiet = 30;
        break;
      case "dinsdagavond":
        dagdeel = "Dins_av";
        Beurtenlimiet = 35;
        break;
      case "dinsdagavond3bklein":
        dagdeel = "Dins_av_3B_klein";
        tafelstegebruiken = [5, 6]
        Beurtenlimiet = 50;
        Spelsoort = "3Banden";
        break;

      case "dinsdagavond3bgroot":
        tafelstegebruiken = [7, 8]
        dagdeel = "Dins_av_3B_groot";
        Beurtenlimiet = 50;
        Spelsoort = "3Banden";
        break;

      case "woensdagavond":
        dagdeel = "Woens_av";
        Beurtenlimiet = 35;
        break;

      case "club70":
        dagdeel = "Club70";
        tafelstegebruiken = [1, 2, 3, 4, 5, 6]
        Beurtenlimiet = 35;
        break;
      case "woensdagavond3bgroot":
        dagdeel = "Woens_av_3B_groot";
        tafelstegebruiken = [7, 8]
        Beurtenlimiet = 40;
        Spelsoort = "3Banden";
        // CompetitieRonde = 2;
        break;
      case "dond5pins":
        dagdeel = "Dond_5P";
        tafelstegebruiken = [1, 2, 3, 4, 5, 6, 7, 8]
        Spelsoort = "5Pins";
        break;

      default:
        dagdeel = "niets";
        break;
    }
    this.setState({
      avondnaam: keys,
      dagdeel,
      Beurtenlimiet,
      Spelsoort,
      CompetitieRonde,
      tafelstegebruiken
    });
    let validUser = false;
    //toast.info(dagdeel);
    if (
      firebase.auth().currentUser.email === "jari.hoeben@outlook.com" &&
      dagdeel === "Woens_av"
    ) {
      validUser = true;
    }

    // Nel
    if (
      firebase.auth().currentUser.email === "nkdevries@casema.nl" &&
      dagdeel === "Woens_av"
    ) {
      validUser = true;
    }
    if (
      firebase.auth().currentUser.email === "jari.hoeben@outlook.com" &&
      dagdeel === "Woens_av2"
    ) {
      validUser = true;
    }
    if (
      firebase.auth().currentUser.email === "jari.hoeben@outlook.com" &&
      dagdeel === "Woens_av3"
    ) {
      validUser = true;
    }
    if (
      firebase.auth().currentUser.email === "jari.hoeben@outlook.com" &&
      dagdeel === "Woens_av4"
    ) {
      validUser = true;
    }
    if (
      firebase.auth().currentUser.email === "club70@gmail.com" &&
      dagdeel === "Club70"
    ) {
      validUser = true;
    }
    if (
      firebase.auth().currentUser.email === "reneverkaik@hotmail.com" &&
      dagdeel === "Club70"
    ) {
      validUser = true;
    }
    if (
      firebase.auth().currentUser.email === "jari.hoeben@outlook.com" &&
      dagdeel === "Dond_5P"
    ) {
      validUser = true;
    }
    // Ik heb toegang tot alle avonden.
    if (firebase.auth().currentUser.email === "p@de-boer.net") {
      validUser = true;
    }

    if (
      firebase.auth().currentUser.email === "petermeijer_52@hotmail.com" &&
      dagdeel === "Dins_av"
    ) {
      validUser = true;
    }
    if (
      firebase.auth().currentUser.email === "franktrappmann@gmail.com" &&
      dagdeel === "Dins_av"
    ) {
      validUser = true;
    }
    if (
      firebase.auth().currentUser.email === "franktrappmann@gmail.com" &&
      dagdeel === "Woens"
    ) {
      validUser = true;
    }

    if (
      firebase.auth().currentUser.email === "slagerijjohanvandoorn@gmail.com" &&
      dagdeel === "Dins_av"
    ) {
      validUser = true;
    }
    if (
      firebase.auth().currentUser.email === "slagerijjohanvandoorn@gmail.com" &&
      dagdeel === "Dond_5P"
    ) {
      validUser = true;
    }
    if (
      firebase.auth().currentUser.email === "alenaers@ziggo.nl" &&
      dagdeel === "Dond_5P"
    ) {
      validUser = true;
    }
    if (
      firebase.auth().currentUser.email === "alenaers@ziggo.nl" &&
      dagdeel === "Woens_av_3B_groot"
    ) {
      validUser = true;
    }
    if (
      firebase.auth().currentUser.email === "j.vlieger@chello.nl" &&
      dagdeel === "Woens_av_3B_groot"
    ) {
      validUser = true;
    }
    if (
      firebase.auth().currentUser.email === "franktrappmann@gmail.com" &&
      dagdeel === "Woens_av_3B_groot"
    ) {
      validUser = true;
    }
    if (
      firebase.auth().currentUser.email === "alenaers@ziggo.nl" &&
      dagdeel === "Maan_av"
    ) {
      validUser = true;
    }
    if (
      firebase.auth().currentUser.email === "hbrethouwer@casema.nl" &&
      dagdeel === "Maan_av"
    ) {
      validUser = true;
    }
    // Ton
    if (
      firebase.auth().currentUser.email === "t.hollenberg@casema.nl" &&
      dagdeel === "Woens_av_3B_groot"
    ) {
      validUser = true;
    }

    if (
      firebase.auth().currentUser.email === "fred.haas49@gmail.com" &&
      dagdeel === "Dins_av_3B_groot"
    ) {
      validUser = true;
    }
    if (
      firebase.auth().currentUser.email === "fred.haas49@gmail.com" &&
      dagdeel === "Dins_av_3B_klein"
    ) {
      validUser = true;
    }



    if (firebase.auth().currentUser.email === "wachtrij@oo67.nl") {
      validUser = true;
    }
    this.setState({ validUser });

    if (!validUser) {
      alert(
        "Je bent niet gerechtigd om deze pagina te gebruiken. Vraag Pierre om wedstrijdleider permissie. 06-26953678. Duurt ong 5 minuten om dit te regelen."
      );
    } else {
      toast.info("Laden van gegevens kan even duren!");
      window.setInterval(() => {
        this.setState({ Update: Date.now() });
      }, 30000);
      this.getData();
    }
  }
  componentDidUpdate(prevProps, prevState, snapshot) {
    if (snapshot !== null) {
      if (
        prevState.Update !== this.state.Update ||
        prevState.CompetitieRonde !== this.state.CompetitieRonde
      ) {
        //Nu word altijd geforceerd de data opgehaald. Zou alleen als de data wijzigd moeten gebeuren.
        //TODO augustus 2019
        if (
          prevState.spelers !== this.state.spelers ||
          prevState.CompetitieRonde !== this.state.CompetitieRonde
        ) {
          this.getData(false);
        } else {
          this.getData(false);
        }
      }
    }
  }

  getData = async update => {
    // var gespeeldewedstrijdenRef = firestore.collection(
    //   "/OnderOns/Wedstrijden/Dins_mid/"
    // );
    // var gespeeldewedstrijden = await gespeeldewedstrijdenRef.get();
    // this.setState(gespeeldewedstrijden.docs);
    //console.log(gespeeldewedstrijden);
    if (!this.state.validUser) return;

    const gespeeldewed = [];
    const aantalwed = [];
    const spelers = [];
    const wachtrij = [];

    var gespeeldewedstrijdenRef = firestore.collection(
      `/OnderOns/Wedstrijden/${this.state.dagdeel}/`
    );
    //console.log(gespeeldewedstrijdenRef);
    const vandaag = new Date();

    let eendagterug = new Date(vandaag).getTime();
    eendagterug -= 5 * 60 * 60 * 1000;
    //vijfuurterug.setDate(vandaag);
    //console.log("Vijf uur terug in milliseconds", eendagterug.getTime());
    //console.log(gister);
    //console.log(gespeeldewedstrijdenRef);
    await gespeeldewedstrijdenRef
      .where(
        "CompetitieRonde",
        "==",
        this.state.CompetitieRonde <= 5 ? this.state.CompetitieRonde : 0
      )
      //.where("Datum", ">", eenweekgeleden)
      //.where("Beurten", ">=", 30)

      // .limit(10)
      .get()
      .then(querySnapshot => {
        querySnapshot.forEach(function (doc) {
          // Eerst de uitslag platmaken
          let tmpRec = [];
          let tmpAantalwed = [];
          var partijduur =
            new Date(doc.data().Eindtijd) - new Date(doc.data().Starttijd);
          var h = new Date(partijduur).getHours() - 1;
          var m = new Date(partijduur).getMinutes();
          // console.log(doc.data().Speler1, doc.data().Speler2, h, m);
          tmpRec = {
            Naam1: doc.data().Speler1 + " " + doc.data().Speler1Achternaam,
            Punten1: doc.data().Speler1PuntenBelgischsysteem,
            Naam2: doc.data().Speler2 + " " + doc.data().Speler2Achternaam,
            Punten2: doc.data().Speler2PuntenBelgischsysteem,
            Datum: doc.data().Datum,
            Details:
              `${doc.data().Speler1} ${doc.data().Speler1Achternaam} heeft:\n ${doc.data().GemaaktSP1
              } van de ${doc.data().TemakenCarambolesSpeler1
              } caramboles gemaakt. \nHS: ${doc.data().Speler1HoogsteSerie
              } \nPunten: ${doc.data().Speler1PuntenBelgischsysteem}\n\n` +
              `Partij was uit in ${doc.data().Beurten
              } beurten en duurde ${h} uur en ${m} minuten.\n\n` +
              `${doc.data().Speler2} ${doc.data().Speler2Achternaam} heeft \n${doc.data().GemaaktSP2
              } van de ${doc.data().TemakenCarambolesSpeler2
              } caramboles gemaakt. \nHS: ${doc.data().Speler2HoogsteSerie
              } \nPunten: ${doc.data().Speler2PuntenBelgischsysteem}`,
            ID: doc.id
          };

          gespeeldewed.push(tmpRec);

          if (tmpRec.Datum > eendagterug) {
            //laatstekeergespeeld - 60 * 24 * 1000) {

            tmpAantalwed = {
              Naam: tmpRec.Naam1,
              Datum: tmpRec.Datum
            };
            aantalwed.push(tmpAantalwed);
            tmpAantalwed = {
              Naam: tmpRec.Naam2,
              Datum: tmpRec.Datum
            };
            aantalwed.push(tmpAantalwed);
          }
        });
      });

    // console.log(gespeeldewed);

    this.setState({ gespeeldewed, aantalwed });

    //De wachtrij inlezen
    var wachtrijref = firestore.collection(
      `/OnderOns/Wachtrij/${this.state.dagdeel}/`
    );

    await wachtrijref
      //.where("Datum", ">", eenweekgeleden)
      //.where("Beurten", ">=", 30)

      // .limit(10)
      // .where("Speler1", ">=", "A")
      .where("IngevoerdOp", ">", 0)
      // .orderBy("Speler1")
      .orderBy("IngevoerdOp")
      .get()
      .then(querySnapshot => {
        querySnapshot.forEach(function (doc) {
          let tmpSp = [];
          tmpSp = {
            Speler1: doc.data().Speler1 + " " + doc.data().Speler1Achternaam,
            Speler2: doc.data().Speler2 + " " + doc.data().Speler2Achternaam,
            id: doc.id,
            IsBezig: doc.data().IsBezig
          };
          //console.log(tmpSp);
          wachtrij.push(tmpSp);
        });
      });
    this.setState({ wachtrij });
    // console.log(wachtrij);

    // Nu de spelers inlezen
    if (!update) {
      var spelersref = firestore.collection(
        `/OnderOns/AlleSpelerslijsten/${this.state.dagdeel}/`
      );
      if (__DEV__) {
        toast.warning(`Spelers inlezen van ${this.state.dagdeel}`, {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true
        });
      }
      let sortvolgorde = "Naam";
      if (this.state.dagdeel.startsWith("Maan")) {
        sortvolgorde = "displayNaam";
      }
      await spelersref
        //.where("Datum", ">", eenweekgeleden)
        //.where("Beurten", ">=", 30)

        // .limit(10)
        .where(sortvolgorde, ">=", "A")

        //.orderBy("Naam")
        .orderBy(sortvolgorde)
        .get()
        .then(querySnapshot => {
          querySnapshot.forEach(function (doc) {
            let tmpSp = [];
            tmpSp = {
              Achternaam: doc.data().Achternaam,
              Naam: doc.data().Naam + " " + doc.data().Achternaam,
              Voornaam: doc.data().Naam,
              Aanwezig: doc.data().Aanwezig,
              Moyenne: doc.data().Moyenne,
              Caramboles: doc.data().Caramboles,
              Vereniging: doc.data().Vereniging,
              Spelsoort: doc.data().SpelSoort,
              DisplayNaam: doc.data().displayNaam
            };
            console.log(tmpSp);
            spelers.push(tmpSp);
          });
        });
      this.setState({ spelers });
      if (spelers.length === 0) {
        toast.warning(`Geen spelers ingelezen`, {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true
        });
      }
    }
  };

  render() {
    const getStorage = () => {
      return firebase.storage();
    };

    const loadImage = async (speler, naam) => {
      // console.log("Naam", naam);
      try {
        let Image1 = await getURL(naam);
        if (Image1 === null && this.state.GeenfotoURL === "") {
          Image1 = await getURL("geenphotovraagteken.jpeg");
          await this.setState({ GeenfotoURL: Image1 });

          //   console.log("GeenfotoURL -2", this.state.GeenfotoURL);
        } else {
          if (Image1 === null && this.state.GeenfotoURL !== "")
            Image1 = this.state.GeenfotoURL;
        }
        //  console.log(Image1);
        this.setState({
          [speler]: Image1
        });
      } catch (error) {
        this.setState({
          [speler]: error
        });
      }
    };

    const getURL = async naam =>
      new Promise(resolve => {
        resolve(
          getStorage()
            .ref()
            .child(naam)
            .getDownloadURL()
        );
      }).catch(err => null);

    const SlaOpinWachtrij = async (sp1, sp2, tafel) => {
      const that = this;
      //Als de partij in de wachtrij staat dan deze verwijderen.
      let wachtrijtmp = [];
      wachtrijtmp = this.state.wachtrij.filter(function (wr) {
        return (
          (wr.Speler1 === sp1 && wr.Speler2 === sp2) ||
          (wr.Speler1 === sp2 && wr.Speler2 === sp1)
        );
      });

      if (wachtrijtmp.length > 0) {
        if (wachtrijtmp[0].IsBezig) {
          toast.warning(
            `Partij van ${sp1} en ${sp2} wordt nu gespeeld. Kan niet worden verwijderd.`,
            {
              position: "top-center",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true
            }
          );
          return;
        }

        try {
          toast.info("Even geduld...");
          const doc = `/OnderOns/Wachtrij/${this.state.dagdeel}/${wachtrijtmp[0].id}`;
          // console.log(doc);
          firebase
            .firestore()
            .doc(doc)
            .delete()
            .then(function (err) {
              toast.success(
                `Partij van ${sp1} en ${sp2} uit de wachtrij gehaald`,
                {
                  position: "top-center",
                  autoClose: 2000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true
                }
              );

              that.setState({ Update: Date.now() });
            });
        } catch (err) {
          toast.error(
            "Er is een fout opgetreden bij het verwijderen van " +
            wachtrijtmp[0].id +
            "\n" +
            err.message,
            {
              position: "top-center",
              autoClose: 10000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true
            }
          );
        }
        return;
      }
      toast.info("Even geduld...", { autoClose: 3000 });
      let SpelenopTafel = tafel;
      let arrsp1 = [];
      let arrsp2 = [];
      if (SpelenopTafel === "") {
        SpelenopTafel = "Wachtrij"; //this.state.Wachtrij.tafel;
      }
      const timestamp = Date.now();

      await loadImage(
        "Speler1URL",
        `${sp1}.jpg`.replace(/ /g, "").toLowerCase()
      );
      //console.log('Speler1URL', this.state.Speler1URL);

      await loadImage(
        "Speler2URL",
        `${sp2}.jpg`.replace(/ /g, "").toLowerCase()
      );

      const Vereniging = "OnderOns";

      const wachtrij = `${Vereniging}/Wachtrij/${this.state.dagdeel}/`;

      // Speler ophalen uit de spelers state
      arrsp1 = this.state.spelers.filter(function (speler) {
        return speler.Naam === sp1;
      });

      arrsp2 = this.state.spelers.filter(function (speler) {
        return speler.Naam === sp2;
      });

      //console.log(CompetitieRonde, dagdeel)
      if (this.state.dagdeel === 'Maan_av' && this.state.CompetitieRonde === 5) {
        //Spelsoort = arrsp1[0].Voorkeur
        if (arrsp1[0].Voornaam !== "Herman" ) arrsp1[0].Caramboles = Math.round(arrsp1[0].Caramboles / 2)

        if (arrsp2[0].voornaam !== "Herman") arrsp2[0].Caramboles = Math.round(arrsp2[0].Caramboles / 2)
      }

      // console.log(this.state.Wachtrij);
      let id = `${timestamp} - ${arrsp1[0].Voornaam} ${arrsp1[0].Achternaam} - ${arrsp2[0].Voornaam} ${arrsp2[0].Achternaam}`
        .replace(/ /g, "")
        .toLowerCase();
      const ref = firebase.firestore().doc(`${wachtrij}${id}/`);
      try {
        //console.log(arrsp1, arrsp2);
        ref
          .set({
            IsBezig: false,
            IngevoerdOp: timestamp,
            ID: id,
            Beurtenlimiet: this.state.Beurtenlimiet || 0,
            Speler1: arrsp1[0].Voornaam,
            Speler1Achternaam: arrsp1[0].Achternaam,
            Speler1Avatarnaam: `${arrsp1[0].Voornaam +
              arrsp1[0].Achternaam}.jpg`
              .replace(/ /g, "")
              .toLowerCase(),
            CompetitieRonde: this.state.CompetitieRonde,
            CarambolesSpeler1: arrsp1[0].Caramboles,
            Speler1Vereniging: arrsp1[0].Vereniging,
            MoyenneSp1: arrsp1[0].Moyenne,

            Speler2: arrsp2[0].Voornaam,
            Speler2Achternaam: arrsp2[0].Achternaam,
            Speler2Avatarnaam: `${arrsp2[0].Voornaam +
              arrsp2[0].Achternaam}.jpg`
              .replace(/ /g, "")
              .toLowerCase(),
            CarambolesSpeler2: arrsp2[0].Caramboles,
            Speler2Vereniging: arrsp2[0].Vereniging,
            MoyenneSp2: arrsp2[0].Moyenne,
            // Thuis: this.state.Wachtrij.Sp1_team || 0,
            //Uit: this.state.Wachtrij.Sp2_team || 0,
            Tafel: SpelenopTafel,
            ShowShotClock: false,
            Beurtprotectie: true,
            Formeel: false,
            Spelsoort: this.state.Spelsoort || "LibreKaderBS",
            SpelersLijst: this.state.dagdeel,
            Speler1URL: this.state.Speler1URL,
            Speler2URL: this.state.Speler2URL,
            Competitie: this.state.dagdeel

            // Emailadres: this.state.Wedstrijd.Emailadres,
          })
          .then(function (err) {
            toast.success(
              `Wedstrijd tussen ${sp1} en ${sp2} in wachtrij gezet`,
              {
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true
              }
            );

            that.setState({ Update: Date.now() });
          });
      } catch (err) {
        toast.error("FOUT !!!!" + err.message, {
          position: "top-center",
          autoClose: 10000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true
        });
      }
    };

    const ZetInWachtrij = (naam, tegenstander) => {
      SlaOpinWachtrij(naam, tegenstander, "");
    };

    const getPunten = (sp1, sp2) => {
      var res;
      try {
        // console.log(sp1);
        res = alasql(
          `Select Punten1 , Punten2,  Naam1   from ? where (Naam1 = '${sp1}' and Naam2 = '${sp2}') or (Naam2 = '${sp1}' and Naam1 = '${sp2}')`,
          [this.state.gespeeldewed]
        );

        // aantal = alasql(
        //   `Select Naam1 ,Count (Naam1) as AantalVandaag  from ? where (Naam1 = '${sp1}' and Naam2 = '${sp2}') or (Naam2 = '${sp1}' and Naam1 = '${sp2} GROUP BY Naam1 , Naam2')`,
        //   [this.state.gespeeldewed]
        // );
        if (res.length > 0) {
          // console.log(res);
        }
      } catch (err) {
        res = err.message;
      }
      return res.length > 0
        ? sp1 === res[0].Naam1
          ? // <Avatar value={res[0].Punten1.toString()} size="24" />
          res[0].Punten1
          : // <Avatar value={res[0].Punten1.toString()} size="24" />
          //
          res[0].Punten2
        : "";
      //return this.state.gespeeldewed.Naam1.filter("Naam1") === sp1 ? "ja" : "nee";
    };
    const getPuntenofNaam = (sp1, sp2) => {
      var res;
      try {
        // console.log(sp1);
        res = alasql(
          `Select Punten1 , Punten2,  Naam1  from ? where (Naam1 = '${sp1}' and Naam2 = '${sp2}') or (Naam2 = '${sp1}' and Naam1 = '${sp2}')`,
          [this.state.gespeeldewed]
        );

        // aantal = alasql(
        //   `Select Naam1 ,Count (Naam1) as AantalVandaag  from ? where (Naam1 = '${sp1}' and Naam2 = '${sp2}') or (Naam2 = '${sp1}' and Naam1 = '${sp2} GROUP BY Naam1 , Naam2')`,
        //   [this.state.gespeeldewed]
        // );
        if (res.length > 0) {
          // console.log(res);
        }
      } catch (err) {
        res = err.message;
      }
      return res.length > 0 ? (
        sp1 === res[0].Naam1 ? (
          // <Avatar value={res[0].Punten1.toString()} size="24" />
          res[0].Punten1
        ) : (
          // <Avatar value={res[0].Punten1.toString()} size="24" />
          //
          res[0].Punten2
        )
      ) : (
        <div style={{ color: "grey", fontSize: 8 }}>
          <Text
            style={{ fontSize: 10 }}
            onPress={() => {
              ZetInWachtrij(sp1, sp2);
            }}
          >
            {sp2.split(" ")[0]}
          </Text>
        </div>
      );
      //return this.state.gespeeldewed.Naam1.filter("Naam1") === sp1 ? "ja" : "nee";
    };

    const AantalPartijenGespeeld = sp1 => {
      var res;
      var aantal = 0;
      try {
        res = alasql(
          `Select Count( Naam) as Aantal  from ? where (Naam = '${sp1}') GROUP BY Naam`,

          [this.state.aantalwed]
        );
        if (res.length > 0) {
          //console.log(res[0].Aantal);
          res.length > 0
            ? res[0].Aantal > 0
              ? (aantal = res[0].Aantal)
              : (aantal = "")
            : (aantal = 0);
        }
      } catch (err) {
        res = err.message;
      }

      return aantal;
    };

    const getAantalWachtrij = sp1 => {
      var kleur = white;
      var res;
      var aantal = 0;
      try {
        res = alasql(
          `Select Count (Speler1) as Aantal from ? where (Speler1 = '${sp1}') or (Speler2 = '${sp1}' )`,
          [this.state.wachtrij]
        );
        if (res.length > 0) {
          aantal = res[0].Aantal;
        }
      } catch (err) {
        res = err.message;
      }
      if (aantal === 0) aantal = "";
      return aantal;
    };

    const getTotaalAantalPartijen = sp1 => {
      var kleur = white;
      var res;
      var aantal = 0;
      try {
        res = alasql(
          `Select Count (Naam1) as Aantal from ? where (Naam1 = '${sp1}') or (Naam2 = '${sp1}' )`,
          [this.state.gespeeldewed]
        );
        if (res.length > 0) {
          aantal = res[0].Aantal;
        }
      } catch (err) {
        res = err.message;
      }
      if (aantal === 0) aantal = "";
      return aantal;
    };

    const getWachtrij = (sp1, sp2) => {
      var kleur = white;
      var res;
      var aantal = 0;
      try {
        res = alasql(
          // `Select Speler1 , IsBezig from ? where ((Speler1 = '${sp1}' and Speler2 = '${sp2}') or (Speler2 = '${sp1}' and Speler1 = '${sp2}') and CompetitieRonde = ${this.state.CompetitieRonde})`,
          `Select Speler1 , IsBezig from ? where ((Speler1 = '${sp1}' and Speler2 = '${sp2}') or (Speler2 = '${sp1}' and Speler1 = '${sp2}'))`,
          [this.state.wachtrij]
        );
        if (res.length > 0) {
          res[0].IsBezig ? (kleur = lichtgeel) : (kleur = lichtgroen);
        }
      } catch (err) {
        res = err.message;
      }

      return kleur;
    };

    const getKleur = (sp1, sp2) => {
      let res;
      let kleur;
      let id = "0";
      let details = "Geen details";

      try {
        // console.log(sp1);
        res = alasql(
          `Select Punten1 , Punten2, Datum, Naam1 , ID , Details from ? where (Naam1 = '${sp1}' and Naam2 = '${sp2}') or (Naam2 = '${sp1}' and Naam1 = '${sp2}')`,
          [this.state.gespeeldewed]
        );
        if (res.length > 0) {
          // console.log(res);
        }
      } catch (err) {
        res = err.message;
      }
      res.length > 0
        ? sp1 === res[0].Naam1
          ? res[0].Punten1 === res[0].Punten2
            ? (kleur = oranje)
            : res[0].Punten1 > res[0].Punten2
              ? (kleur = blauw)
              : (kleur = rood)
          : res[0].Punten1 === res[0].Punten2
            ? (kleur = oranje)
            : res[0].Punten2 > res[0].Punten1
              ? (kleur = blauw)
              : (kleur = rood)
        : (kleur = white);
      try {
        var d = new Date(Date.now());
        var g = new Date(res[0].Datum);
        if (d.toDateString() === g.toDateString()) {
          kleur = lichtblauw;
        }
        id = res[0].ID;
        details = res[0].Details;
      } catch (err) {
        toast.error("FOUT !!!!" + sp1 + sp2 + err.message, {
          position: "top-center",
          autoClose: 1000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true
        });
      }

      //wijzig kleur in object en geef Id

      const ret = {
        kleur,
        ID: id,
        Details: details
      };
      return ret;
      //return this.state.gespeeldewed.Naam1.filter("Naam1") === sp1 ? "ja" : "nee";
    };

    const HaalPartijenenPuntenOp = (vn, aanwezig) =>
      this.state.spelers.map(function (d, idx) {
        return vn === d.Voornaam + " " + d.Achternaam ||
          (d.Aanwezig === false || aanwezig === false) ? (
          d.Aanwezig ? (
            <td
              key={idx}
              align="center"
              background={
                d.Aanwezig ? (aanwezig ? blackpixel : lichtgrijs) : lichtgrijs
              }
              style={{
                height: 20,
                width: "3.2%"
              }}
            >
              {/* <img src={blackpixel} width="100%" height="100%" alt="black" /> */}
            </td>
          ) : null
        ) : (
          <td
            key={idx}
            align="center"
            vertical-align="center"
            text-align="center"
            position="relative"
            //overflow="hidden"
            background={
              getPunten(vn, d.Naam) === ""
                ? // ? white Hier komt de wachtrij
                getWachtrij(vn, d.Naam)
                : getKleur(vn, d.Naam, d.Aanwezig).kleur
            }
            onClick={() => {
              const res = getKleur(vn, d.Naam, d.Aanwezig);
              alert(res.Details);
            }}
            style={{
              // backgroundColor: "white",
              width: "3.2%",
              color: "white",
              fontWeight: "bold",
              fontSize: 14
              //borderRadius: 100
            }}
          >
            {/* <img
              src={getKleur(vn, d.Naam)}
              width="100%"
              height="26"
              style={{ zIndex: 1 }}
              alt="achtergrond"
            /> */}
            <div
              style={{
                zIndex: 2,
                //position: "relative",
                //top: 0,
                fontSize: 10,
                color: "white"
              }}
            >
              {getPuntenofNaam(vn, d.Naam)}
            </div>
          </td>
        );
      });

    const ZetAfwezig = async (voornaam, achternaam, forceeraanwezig) => {
      toast.success(
        `Aanwezigheid van ${voornaam} ${achternaam} wordt aangepast. Even geduld.`,
        {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true
        }
      );
      let IsNuAanwezig = true;
      if (!forceeraanwezig) {
        let arrsp = this.state.spelers.filter(function (speler) {
          return speler.Naam === voornaam + " " + achternaam;
        });
        //console.log(arrsp);
        if (arrsp.length > 0) {
          IsNuAanwezig = arrsp[0].Aanwezig;
        }
      } else {
        IsNuAanwezig = false;
      }
      const that = this;
      var id = `${voornaam} ${achternaam}`.replace(/ /g, "");
      var spelersref = firestore.collection(
        `/OnderOns/AlleSpelerslijsten/${this.state.dagdeel}/`
      );
      await spelersref
        .doc(id)
        .update({ Aanwezig: !IsNuAanwezig })
        .then(function (err) {
          that.setState({ Update: Date.now() });
        });
    };

    const switchCompetitie = value => {
      // console.log('Switch', value);
      this.setState({ Competitie: value });
    };
    return (
      <div>
        {this.state.validUser &&
          firebase.auth().currentUser.email !== "wachtrij@oo67.nl" && (
            <div>
              <div style={{ marginBottom: 20 }}>
                {" "}
                <Text
                  style={{
                    padding: 7,
                    marginLeft: 20,
                    borderRadius: 10,
                    borderColor: "black",
                    backgroundColor: "blue",
                    color: "white"
                  }}
                >
                  Matrix {this.state.avondnaam}{" "}
                </Text>

                {_.indexOf(this.state.tafelstegebruiken, 1) > -1 && (<Text
                  style={{
                    padding: 7,
                    marginLeft: 20,
                    borderRadius: 10,
                    borderColor: "black",
                    backgroundColor: "gold",
                    color: "black"
                  }}
                  onPress={() =>
                    getDatabase()
                      .ref()
                      .child("BiljartWedstrijd")
                      .child("OnderOns/Tafel1")
                      .update({
                        ActieveSpelersLijst: this.state.dagdeel,
                        Beurtenlimiet: this.state.Beurtenlimiet
                      })
                      .then(function (x) {
                        toast.success("Tafel 1 kan worden gebruikt");
                      })
                  }
                >
                  Tafel 1
                </Text>)}
                {_.indexOf(this.state.tafelstegebruiken, 2) > -1 && (<Text
                  style={{
                    padding: 7,
                    marginLeft: 20,
                    borderRadius: 10,
                    borderColor: "black",
                    backgroundColor: "gold",
                    color: "black"
                  }}
                  onPress={() =>
                    getDatabase()
                      .ref()
                      .child("BiljartWedstrijd")
                      .child("OnderOns/Tafel2")
                      .update({
                        ActieveSpelersLijst: this.state.dagdeel,
                        Beurtenlimiet: this.state.Beurtenlimiet
                      })
                      .then(function (x) {
                        toast.success("Tafel 2 kan worden gebruikt");
                      })
                  }
                >
                  Tafel 2
                </Text>)}
                {_.indexOf(this.state.tafelstegebruiken, 3) > -1 && (<Text
                  style={{
                    padding: 7,
                    marginLeft: 20,
                    borderRadius: 10,
                    borderColor: "black",
                    backgroundColor: "gold",
                    color: "black"
                  }}
                  onPress={() =>
                    getDatabase()
                      .ref()
                      .child("BiljartWedstrijd")
                      .child("OnderOns/Tafel3")
                      .update({
                        ActieveSpelersLijst: this.state.dagdeel,
                        Beurtenlimiet: this.state.Beurtenlimiet
                      })
                      .then(function (x) {
                        toast.success("Tafel 3 kan worden gebruikt");
                      })
                  }
                >
                  Tafel 3
                </Text>)}
                {_.indexOf(this.state.tafelstegebruiken, 4) > -1 && (<Text
                  style={{
                    padding: 7,
                    marginLeft: 20,
                    borderRadius: 10,
                    borderColor: "black",
                    backgroundColor: "gold",
                    color: "black"
                  }}
                  onPress={() =>
                    getDatabase()
                      .ref()
                      .child("BiljartWedstrijd")
                      .child("OnderOns/Tafel4")
                      .update({
                        ActieveSpelersLijst: this.state.dagdeel,
                        Beurtenlimiet: this.state.Beurtenlimiet
                      })
                      .then(function (x) {
                        toast.success("Tafel 4 kan worden gebruikt");
                      })
                  }
                >
                  Tafel 4
                </Text>)}
                {_.indexOf(this.state.tafelstegebruiken, 5) > -1 && (<Text
                  style={{
                    padding: 7,
                    marginLeft: 20,
                    borderRadius: 10,
                    borderColor: "black",
                    backgroundColor: "gold",
                    color: "black"
                  }}
                  onPress={() =>
                    getDatabase()
                      .ref()
                      .child("BiljartWedstrijd")
                      .child("OnderOns/Tafel5")
                      .update({
                        ActieveSpelersLijst: this.state.dagdeel,
                        Beurtenlimiet: this.state.Beurtenlimiet
                      })
                      .then(function (x) {
                        toast.success("Tafel 5 kan worden gebruikt");
                      })
                  }
                >
                  Tafel 5
                </Text>)}
                {_.indexOf(this.state.tafelstegebruiken, 6) > -1 && (<Text
                  style={{
                    padding: 7,
                    marginLeft: 20,
                    borderRadius: 10,
                    borderColor: "black",
                    backgroundColor: "gold",
                    color: "black"
                  }}
                  onPress={() =>
                    getDatabase()
                      .ref()
                      .child("BiljartWedstrijd")
                      .child("OnderOns/Tafel6")
                      .update({
                        ActieveSpelersLijst: this.state.dagdeel,
                        Beurtenlimiet: this.state.Beurtenlimiet
                      })
                      .then(function (x) {
                        toast.success("Tafel 6 kan worden gebruikt");
                      })
                  }
                >
                  Tafel 6
                </Text>)}
                {_.indexOf(this.state.tafelstegebruiken, 7) > -1 && (<Text
                  style={{
                    padding: 7,
                    marginLeft: 20,
                    borderRadius: 10,
                    borderColor: "black",
                    backgroundColor: "gold",
                    color: "black"
                  }}
                  onPress={() =>
                    getDatabase()
                      .ref()
                      .child("BiljartWedstrijd")
                      .child("OnderOns/Tafel7")
                      .update({
                        ActieveSpelersLijst: this.state.dagdeel,
                        Beurtenlimiet: this.state.Beurtenlimiet
                      })
                      .then(function (x) {
                        toast.success("Matchafel 1 kan worden gebruikt");
                      })
                  }
                >
                  Groot 1
                </Text>)}
                {_.indexOf(this.state.tafelstegebruiken, 8) > -1 && (<Text
                  style={{
                    padding: 7,
                    marginLeft: 20,
                    borderRadius: 10,
                    borderColor: "black",
                    backgroundColor: "gold",
                    color: "black"
                  }}
                  onPress={() =>
                    getDatabase()
                      .ref()
                      .child("BiljartWedstrijd")
                      .child("OnderOns/Tafel8")
                      .update({
                        ActieveSpelersLijst: this.state.dagdeel,
                        Beurtenlimiet: this.state.Beurtenlimiet
                      })
                      .then(function (x) {
                        toast.success("Matchtafel 2 kan worden gebruikt");
                      })
                  }
                >
                  Groot 2
                </Text>)}
                <Text
                  style={{
                    padding: 7,
                    marginLeft: 20,
                    borderRadius: 10,
                    borderColor: "black",
                    //border: 3,
                    backgroundColor:
                      this.state.CompetitieRonde === 1 ? "green" : "blue",
                    fontSize: this.state.CompetitieRonde === 1 ? 24 : 12,
                    color: "white"
                  }}
                  onPress={() => {
                    this.setState({ CompetitieRonde: 1 });
                  }}
                >
                  Periode 1
                </Text>
                <Text
                  style={{
                    padding: 7,
                    marginLeft: 20,
                    borderRadius: 10,
                    borderColor: "black",
                    fontSize: this.state.CompetitieRonde === 2 ? 24 : 12,
                    backgroundColor:
                      this.state.CompetitieRonde === 2 ? "green" : "blue",
                    color: "white"
                  }}
                  onPress={() => {
                    this.setState({ CompetitieRonde: 2 });
                  }}
                >
                  Periode 2
                </Text>
                <Text
                  style={{
                    padding: 7,
                    marginLeft: 20,
                    borderRadius: 10,
                    borderColor: "black",
                    fontSize: this.state.CompetitieRonde === 3 ? 24 : 12,
                    backgroundColor:
                      this.state.CompetitieRonde === 3 ? "green" : "blue",
                    color: "white"
                  }}
                  onPress={() => {
                    this.setState({ CompetitieRonde: 3 });
                  }}
                >
                  Periode 3
                </Text>
                <Text
                  style={{
                    padding: 7,
                    marginLeft: 20,
                    borderRadius: 10,
                    borderColor: "black",
                    fontSize: this.state.CompetitieRonde === 4 ? 24 : 12,
                    backgroundColor:
                      this.state.CompetitieRonde === 4 ? "green" : "blue",
                    color: "white"
                  }}
                  onPress={() => {
                    this.setState({ CompetitieRonde: 4 });
                  }}
                >
                  Periode 4
                </Text>
                <Text
                  style={{
                    padding: 7,
                    marginLeft: 20,
                    borderRadius: 10,
                    borderColor: "black",
                    fontSize: this.state.CompetitieRonde === 5 ? 24 : 12,
                    backgroundColor:
                      this.state.CompetitieRonde === 5 ? "green" : "blue",
                    color: "white"
                  }}
                  onPress={() => {
                    this.setState({ CompetitieRonde: 5 });
                  }}
                >
                  {this.state.dagdeel === "Maan_av"
                    ? "Bandstoten"
                    : "Periode 5"}
                </Text>
                <Text
                  style={{
                    padding: 7,
                    marginLeft: 20,
                    borderRadius: 10,
                    borderColor: "black",
                    fontSize: this.state.CompetitieRonde === 6 ? 24 : 12,
                    backgroundColor:
                      this.state.CompetitieRonde === 6 ? "green" : "blue",
                    color: "white"
                  }}
                  onPress={() => {
                    this.setState({ CompetitieRonde: 6 });
                  }}
                >
                  Vrije partijen
                </Text>
                {/* <Text
            style={{
              padding: 7,
              marginLeft: 20,
              borderRadius: 10,
              borderColor: "black",
              backgroundColor: "green",
              color: "white"
            }}
            onPress={() =>
              this.state.spelers.map(function(s, idx) {
                ZetAfwezig(s.Voornaam, s.Achternaam, true);
              })
            }
          >
            Alle spelers aanwezig
          </Text> */}
              </div>

              {/* {toast.info("Laden matrix. Dit duurt even")} */}
              <div
                id="matrix"
                style={{
                  maxWidth: 1550,
                  paddingBottom: 10,
                  paddingLeft: 3,
                  paddingRight: 3
                }}
              >
                <table
                  border="1"
                  border-collapse="collapse"
                  cellspacing="0"
                  cellpadding="0"
                  width="100%"
                >
                  <thead>
                    <tr key="1">
                      <th width="10%" white-space="nowrap" />
                      <th
                        style={{ align: "center", fontSize: 6 }}
                        width="1.5%"
                        white-space="nowrap"
                      >
                        Gesp.
                      </th>
                      <th
                        style={{ alignContent: "center", fontSize: 6 }}
                        width="1.5%"
                        white-space="nowrap"
                      >
                        W.rij
                      </th>

                      {this.state.spelers.map(function (d, idx) {
                        if (d.Aanwezig) {
                          return (
                            <th
                              key={idx}
                              style={{
                                fontSize: 8,
                                textAlign: "center",
                                fontWeight: "bold"
                                //width: "2%"
                              }}
                            >
                              <div>{d.DisplayNaam}</div>
                            </th>
                          );
                        }
                      })}

                      <th
                        style={{ alignContent: "center", fontSize: 6 }}
                        width="1.5%"
                        white-space="nowrap"
                      >
                        Tot wed
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.spelers.map(function (d, idx) {
                      if (d.Aanwezig) {
                        let naam = `${d.Voornaam} ${d.Achternaam}`;
                        let aantalpartijen = 0;
                        naam = naam
                          .replace("van der", "vd")
                          .replace("van den", "vd");
                        let afgekortenaam =
                          naam.length > 15 ? naam.substring(0, 15) : naam;

                        let car = "";

                        if (
                          d.Spelsoort === "3Banden" ||
                          d.Vereniging === "OnderOns Woensdagavond"
                        ) {
                          car = `(${d.Caramboles})`;
                        }

                        return (
                          <tr key={idx}>
                            <td
                              style={{
                                paddingTop: 2,
                                paddingBottom: 2,
                                paddingLeft: 2,
                                fontWeight: "normal"
                              }}
                            >
                              <Text
                                style={{}}
                                onPress={() =>
                                  ZetAfwezig(d.Voornaam, d.Achternaam)
                                }
                              >
                                {afgekortenaam} {car}
                              </Text>
                            </td>
                            <td align="center">
                              {AantalPartijenGespeeld(
                                d.Voornaam + " " + d.Achternaam
                              )}
                            </td>
                            <td align="center">
                              {getAantalWachtrij(
                                d.Voornaam + " " + d.Achternaam
                              )}
                            </td>

                            {HaalPartijenenPuntenOp(
                              d.Voornaam + " " + d.Achternaam,
                              d.Aanwezig
                            )}
                            <td align="center">
                              {getTotaalAantalPartijen(
                                d.Voornaam + " " + d.Achternaam
                              )}
                            </td>
                          </tr>
                        );
                      }
                    })}
                  </tbody>
                </table>
              </div>
              {/* <div>
          Lichtblauw = vandaag, gespeeld, geel = wordt gespeeld , oranje = wachtrij , donkerblauw = gewonnen, rood =
          verloren , oranje = remise
        </div> */}
              <div align="left" style={{ margin: 5 }}>
                Afwezig zijn :
                {this.state.spelers.map(function (d, idx) {
                  if (!d.Aanwezig) {
                    let naam = `${d.Voornaam} ${d.Achternaam}`;
                    naam = naam
                      .replace("van der", "vd")
                      .replace("van den", "vd");
                    let afgekortenaam =
                      naam.length > 15 ? naam.substring(0, 15) : naam;
                    return (
                      <Text
                        key={idx}
                        style={{
                          padding: 7,
                          marginLeft: 20,
                          //paddingBottom: 20,
                          borderRadius: 10,
                          borderColor: "black",
                          backgroundColor: "green",
                          color: "white"
                        }}
                        onPress={() => ZetAfwezig(d.Voornaam, d.Achternaam)}
                      >
                        {afgekortenaam}
                      </Text>
                    );
                  }
                })}
                <Text
                  style={{
                    padding: 7,
                    marginLeft: 20,
                    borderRadius: 10,
                    borderColor: "black",
                    backgroundColor: "blue",
                    color: "white"
                  }}
                  onPress={() => {
                    html2canvas(document.querySelector("#matrix")).then(
                      canvas => {
                        document.getElementsByTagName("body")[0].innerHTML =
                          "<p>&nbsp;</p>";
                        document.body.appendChild(canvas);
                      }
                    );
                  }}
                >
                  Maak screenshot om in kleur te kunnen printen
                </Text>
                <Text
                  style={{
                    padding: 7,
                    marginLeft: 20,
                    borderRadius: 10,
                    borderColor: "black",
                    backgroundColor: "green",
                    color: "white"
                  }}
                  onPress={() => this.setState({ Update: Date.now() })}
                >
                  Ververs pagina
                </Text>
                {/* <Switch
            onValueChange={() => switchCompetitie()}
            value={this.state.Competitie}
          /> */}
              </div>

              <div />

              <ToastContainer
                position="top-center"
                transition={Zoom}
                autoClose={2000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick={false}
                rtl={false}
                pauseOnVisibilityChange
                draggable
                pauseOnHover
                closeButton={false}
              />
            </div>
          )}
        <div>
          {this.state.wachtrij.length === 0 && (
            <div
              style={{
                marginTop: 20,
                height: "100%",
                margin: 5,
                padding: 5,
                align: "center"
              }}
            >
              <p>
                <Text
                  style={{
                    color: "Blue",
                    fontSize:
                      firebase.auth().currentUser.email === "wachtrij@oo67.nl"
                        ? 36
                        : 14,
                    margin: 5,
                    padding: 5
                    //borderRadius: "10px",
                    //marginRight: "20px"
                  }}
                  key="Bezig"
                >
                  Geen partijen ingedeeld op dit moment
                </Text>
              </p>
            </div>
          )}
          {this.state.wachtrij.length > 0 && (
            <div
              style={{ marginTop: 20, height: "100%", margin: 5, padding: 5 }}
            >
              <p>
                <Text
                  style={{
                    color: "Blue",
                    fontSize:
                      firebase.auth().currentUser.email === "wachtrij@oo67.nl"
                        ? 36
                        : 14,
                    margin: 5,
                    padding: 5
                    //borderRadius: "10px",
                    //marginRight: "20px"
                  }}
                  key="Bezig"
                >
                  Partijen voor vandaag:
                </Text>
              </p>
              <View
                style={{
                  flex: 1,
                  flexWrap: "wrap",
                  flexDirection: "row",
                  width: "100%",
                  alignContent: "center"
                }}
              >
                {this.state.wachtrij
                  .filter(w => {
                    return w.IsBezig === false;
                  })
                  .map(function (w, idx) {
                    const title =
                      `(${idx + 1}) ` + w.Speler1 + " tegen " + w.Speler2;
                    let kleur = idx % 2 === 0 ? "green" : "blue";
                    //console.log(kleur);
                    return (
                      <View
                        style={{
                          width: "50%",
                          borderRadius: "25px"
                          //backgroundColor: kleur
                        }}
                      >
                        <p style={{ margin: 5 }}>
                          <Button
                            color={kleur}
                            key={idx}
                            title={title} // tegen w.Speler2}
                          />
                        </p>
                      </View>
                    );
                  })}
              </View>

              <p>
                <Text
                  style={{
                    color: "Blue",
                    fontSize:
                      firebase.auth().currentUser.email === "wachtrij@oo67.nl"
                        ? 36
                        : 14,
                    margin: 5,
                    padding: 5
                    //borderRadius: "10px",
                    //marginRight: "20px"
                  }}
                  key="Bezig"
                >
                  Nu spelen:
                </Text>
              </p>
              <View
                style={{
                  flex: 1,
                  flexWrap: "wrap",
                  flexDirection: "row",
                  width: "100%",
                  alignContent: "center"
                }}
              >
                {this.state.wachtrij
                  .filter(w => {
                    return w.IsBezig === true;
                  })
                  .map(function (w, idx) {
                    const title = w.Speler1 + " tegen " + w.Speler2;
                    return (
                      <p style={{ margin: 5 }}>
                        <Button color="steelblue" key="Bezig" title={title} />
                      </p>
                    );
                  })}
              </View>
            </div>
          )}
        </div>
      </div>
    );
  }
}
export default Matrix;
